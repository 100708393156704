import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const CategoryNavigation = () => {
  const categories = [
    { 
      name: "Automobiles", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-fbbeccd70898d4e7205355be96786cf4.png",
      url:"https://abcswap.com/category/automobiles"
    },
    { 
      name: "Phones & Tablets", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-2c0442ac95428ecdecac19324d49b1b7.png",
      url:"https://abcswap.com/category/phones-and-tablets"
    },
    { 
      name: "Electronics", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-a54e7ec7cc455930adb1c9b7ca2f76fa.png",
      url:"https://abcswap.com/category/electronics" 
    },
    { 
      name: "Furniture & Accessories", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-0e9db5195b83671c94606c3d3eb7dbf8.png" ,
      url:"https://abcswap.com/category/furniture-appliances"
    },
    { 
      name: "Real estate", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-c9e0155064a849c9e57f81b2bc95a728.png" ,
      url:"https://abcswap.com/category/real-estate"
    },
    { 
      name: "Wanted", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-200d2e7d9e9c115851d349f2d24c6123.png",
      url:"https://abcswap.com/category/wanted"
    },
    { 
      name: "Fashion", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-32571a116da2f0a907db85ea987fb2a7.png",
      url:"https://abcswap.com/category/fashion"
    },
    { 
      name: "Beauty & Well being", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-380f79088705d455786b46ad03d2850e.png",
      url:"https://abcswap.com/category/beauty-well-being" 
    },
    { 
      name: "Jobs", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-9b083978daa61aa4aa958da86f2c7de7.png",
      url:"https://abcswap.com/category/jobs" 
    },
    { 
      name: "Services", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-fc86e86e449278e5b380cebb75ec142a.png",
      url:"https://abcswap.com/category/services"
    },
    { 
      name: "Learning", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-e0395a44e810b4087d346822a46b3d32.png",
      url:"https://abcswap.com/category/learning" 
    },
    { 
      name: "Local Events", 
      icon: "https://abcswap.com/public/storage/app/categories/custom/thumb-70x70-522740852bed0f1b002dbba1863b9ccc.png",
      url:"https://abcswap.com/category/local-events"
    },
  ];
  const visibleCategories = [
    { name: 'Categories', icon: <FontAwesomeIcon icon={faBars} />, url: '#' },
    ...categories
  ];

  return (
    <div className="category-container">
      <div className="category-navigation">
        {visibleCategories.map((category, index) => (
          <a 
            key={index} 
            href={category.url} 
            className={`category ${index === 0 ? 'categories-menu' : ''}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <div className="category-icon">
              {typeof category.icon === 'string' ? (
                <img src={category.icon} alt={category.name} />
              ) : (
                category.icon
              )}
            </div>
            <div className="category-name">{category.name}</div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default CategoryNavigation;