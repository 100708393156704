import React from 'react';

const locations = [
  { name: "All", id: null },
  { name: "Dubai", id: 7 },
  { name: "Sharjah", id: 10 },
  { name: "Abu Dhabi", id: 17 },
  { name: "Ajman City", id: 15 },
  { name: "Ras Al Khaimah City", id: 2 },
  { name: "Umm Al Quwain City", id: 1 }
];

const LocationNavigation = () => {
  const handleLocationClick = (location) => {
    if (location.name === "All") {
      window.location.href = "https://abcswap.com/search";
    } else {
      
      const formattedLocation = location.name.toLowerCase().replace(/\s+/g, '-');
      const url = `https://www.abcswap.com/location/${formattedLocation}/${location.id}`;
      window.location.href = url;
    }
  };

  return (
    <div className="location-navigation-container">
      <div className="location-navigation">
        {locations.map((location) => (
          <div 
            key={location.id || 'all'} 
            className="location" 
            onClick={() => handleLocationClick(location)}
            style={{ cursor: 'pointer' }}
          >
            {location.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocationNavigation;