import React from 'react';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3>ABOUT US</h3>
        <ul>
          <li><a href="/faq">FAQ</a></li>
          <li><a href="/anti-scam">Anti-Scam</a></li>
          <li><a href="/terms">Terms</a></li>
          <li><a href="/privacy">Privacy</a></li>
        </ul>
      </div>
      
      <div className="footer-section">
        <h3>CONTACT &amp; SITEMAP</h3>
        <ul>
          <li><a href="/contact">Contact Us</a></li>
          <li><a href="/sitemap">Sitemap</a></li>
        </ul>
      </div>
      
      <div className="footer-section">
        <h3>MY ACCOUNT</h3>
        <ul>
          <li><a href="/login">Log In</a></li>
          <li><a href="/register">Register</a></li>
        </ul>
      </div>
      
      <div className="footer-section">
        <h3>FOLLOW US ON</h3>
        <div className="social-icons">
          <a href="#" className="social-icon facebook">Facebook</a>
          <a href="#" className="social-icon twitter">Twitter</a>
          <a href="#" className="social-icon instagram">Instagram</a>
          <a href="#" className="social-icon linkedin">LinkedIn</a>
          <a href="#" className="social-icon pinterest">Pinterest</a>
          <a href="#" className="social-icon tiktok">TikTok</a>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>© 2024 abc swap. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;