import React, { useState } from 'react';
import { Button, Form, InputGroup, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      const encodedQuery = encodeURIComponent(searchQuery);
      window.location.href = `https://www.abcswap.com/search?q=${encodedQuery}&l=`;
    }
  };

  return (
    <Container fluid className="search-bar-container">
      <Form className="search-bar-form" onSubmit={handleSearch}>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="What?"
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputGroup.Text className="search-button-spacer"></InputGroup.Text>
          <Button variant="success" className="search-button" type="submit">
            <FontAwesomeIcon icon={faSearch} /> Find
          </Button>
        </InputGroup>
      </Form>
    </Container>
  );
};

export default SearchBar;