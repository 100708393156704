import React from 'react';
import Header from './components/Header';
import CategoryNavigation from './components/CategoryNavigation';
import LocationNavigation from './components/LocationNavigation';
import Footer from './components/Footer';
import RandomListings from './components/RandomListings';
import StatisticsBar from './components/StatisticsBar';
import './styles.css'
import './App.css'
import SearchBar from './components/SearchBar';






const App = () => {
  return (
    <div className="app-wrapper">
      <Header />
      <main className="main-container">
        <SearchBar />
        <CategoryNavigation />
        <LocationNavigation />
        <RandomListings />
        {/* <StatisticsBar /> */}
      </main>
      <Footer />
    </div>
  );
};

export default App;
