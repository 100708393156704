import React, { useState } from 'react';
import { Modal, Input, Button, Typography, Space, message } from 'antd';
import { TagOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';


const { TextArea } = Input;
const { Title } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  }

  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    padding: 12px 24px;
    background-color: #ffffff;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-modal-body {
    padding: 10px 10px;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 0 10px 10px;
  }

  top: 20px;
  padding-bottom: 0;
  
  @media (max-width: 768px) {
    margin: 0 auto;
    width: calc(100vw - 16px) !important;
  }
`;

const HeaderIcon = styled(TagOutlined)`
  font-size: 20px;
  color: #1890ff;
  margin-right: 8px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 5px;
`;

const StyledTextArea = styled(TextArea)`
  margin-bottom: 10px;
`;

const OfferModal = ({ open, handleClose, onSubmit, postId }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!name || !description) {
      message.error('Please fill in all fields');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('https://api.abcswap.com/public/api/postoffers', {
        name,
        description,
        post_id: postId
      });
      message.success('Offer placed successfully');
      onSubmit({ name, description, post_id: postId });
      handleClose();
    } catch (error) {
      message.error('Failed to place offer. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
      setName('');
    setDescription('');
    }
  };

  return (
    <StyledModal
      title={
        <Space>
          <HeaderIcon style={{ color: 'green' }} />
          <Title level={4} style={{ margin: 0, fontSize: '18px', fontWeight: 'bold' }}>OFFER</Title>
        </Space>
      }
      open={open}
      onCancel={handleClose}
      footer={null}
      width={400}
      closeIcon={<CloseOutlined style={{ fontSize: '14px' }} />}
      style={{ top: 20 }}
    >
      <StyledInput
        placeholder="Type your name here"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <StyledTextArea
        placeholder="Type your offer description here"
        rows={3}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button 
        style={{backgroundColor:'#1A9639', color:'white', fontWeight:'bold'}} 
        block 
        onClick={handleSubmit}
        loading={loading}
      >
        Send
      </Button>
    </StyledModal>
  );
};

export default OfferModal;
