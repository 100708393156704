import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import ListingCard from './ListingCard';
import OfferModal from './OfferModal';
import { HiMiniBars3 } from "react-icons/hi2";

const RandomListings = () => {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [autoLoadEnabled, setAutoLoadEnabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [offers, setOffers] = useState({});

  const loadMoreRef = useRef(null);

  const ITEMS_PER_PAGE = 12;
  const API_URL = 'https://api.abcswap.com/public/api/posts';
  const IMAGE_BASE_URL = 'https://abcswap.com/public/storage/';
  const DEFAULT_IMAGE = 'https://via.placeholder.com/300x225';

  useEffect(() => {
    loadListings();
  }, []);

  useEffect(() => {
    if (autoLoadEnabled) {
      const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: "20px",
        threshold: 1.0
      });
      if (loadMoreRef.current) {
        observer.observe(loadMoreRef.current);
      }
      return () => observer.disconnect();
    }
  }, [autoLoadEnabled, isLoading, hasMore]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading && hasMore) {
      loadListings();
    }
  };

  const loadListings = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await axios.get(API_URL, {
        params: { limit: ITEMS_PER_PAGE, offset: page * ITEMS_PER_PAGE }
      });

      const newListings = response.data;
      setListings(prev => [...prev, ...newListings]);
      setPage(prev => prev + 1);
      setHasMore(newListings.length === ITEMS_PER_PAGE);
      
      // Fetch offers for each new listing
      newListings.forEach(async (listing) => {
        const offerResponse = await axios.get(`https://api.abcswap.com/public/api/offersbypostid/${listing.id}`);
        setOffers(prev => ({ ...prev, [listing.id]: offerResponse.data }));
      });
    } catch (error) {
      console.error('Error fetching listings:', error);
    }

    setIsLoading(false);
  };

  const handleLoadMore = () => {
    loadListings();
    setAutoLoadEnabled(true);
  };

  const getImageUrl = (filename) => {
    return filename ? `${IMAGE_BASE_URL}${filename}` : DEFAULT_IMAGE;
  };

  const generateListingUrl = (title, id) => {
    const name = title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
    return `https://abcswap.com/${name}/${id}`;
  };

  const handleOpenModal = (listing) => {
    setSelectedListing(listing);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedListing(null);
  };

  const handleSubmitOffer = (offerData) => {
    console.log('Offer submitted:', offerData, 'for listing:', selectedListing);
    setOffers(prev => ({
      ...prev,
      [selectedListing.id]: [offerData, ...prev[selectedListing.id] || []]
    }));
  };

  return (
    <Container fluid className="randomly-selected-listings my-4">
      <Row className="mb-3 align-items-center">
        <Col>
          <h2 style={{fontSize: '20px', fontWeight: 'light'}}>
            <span style={{fontWeight: 'bold'}}>Randomly Selected</span> Listings
          </h2>
        </Col>
        
        <Col className="text-end ">
          <a className='view-more' href="https://abcswap.com/search">View More<HiMiniBars3 color='green' size={15} /></a>
        </Col>
      </Row>
      <Row className="g-2 g-md-2">
        {listings.map((listing) => (
          <Col key={listing.id} xs={6} md={2} lg={2} className="mb-3">
            <ListingCard
              image={getImageUrl(listing.filename)}
              title={listing.title}
              price={parseFloat(listing.price)}
              currency={listing.currency_code}
              offers={offers[listing.id] || []}
              listingUrl={generateListingUrl(listing.title, listing.id)}
              onPlaceOffer={() => handleOpenModal(listing)}
            />
          </Col>
        ))}
      </Row>
      {hasMore && (
        <div className="text-center mt-4" ref={loadMoreRef}>
          {!autoLoadEnabled ? (
            <Button 
              variant="outline-secondary"
              onClick={handleLoadMore}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Load More'}
            </Button>
          ) : (
            isLoading && <p>Loading more listings...</p>
          )}
        </div>
      )}
      <OfferModal
        open={modalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleSubmitOffer}
        postId={selectedListing?.id}
      />
    </Container>
  );
};

export default RandomListings;
