import React from 'react';

const ListingCard = ({ image, title, price, currency, offers, listingUrl, onPlaceOffer }) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (listingUrl) {
      window.location.href = listingUrl;
    }
  };

  const handlePlaceOffer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onPlaceOffer();
  };

  return (
    <div className="listing-card">
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        <img src={image} alt={title} className="listing-image" />
      </div>
      <div className="listing-details">
        <div className="row1">
          <h3 className="listing-title" onClick={handleClick} style={{ cursor: 'pointer' }}>{title}</h3>
          <p className="listing-price">{price} {currency}</p>
        </div>
        <div className="place-offer" onClick={handlePlaceOffer} style={{ cursor: 'pointer' }}>
          <p className="offer-text">Swap or Offer</p>
          <div className="favorite-icon">♡</div>
        </div>
        {offers.length > 0 ? (
          <div className="offers" style={{fontSize: '10px' }} >
            {offers.slice(0, 2).map((offer, index) => (
              <p style={{marginBottom: '2px'}} key={index}><strong>{offer.name}:</strong> {offer.description}</p>
            ))}
          </div>
        ) : (
          <p className="no-offers">No offers available.</p>
        )}
      </div>
    </div>
  );
};

export default ListingCard;
