import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Image, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTags, faGlobe, faBars } from '@fortawesome/free-solid-svg-icons';
import { SlNote } from "react-icons/sl";
import { FaUser, FaRegUser } from "react-icons/fa";
import logo from '../logo.png';
import flag from '../ae.png';
import '../styles.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <Navbar expand="lg" className="header">
      <div className="header-content">
        <Navbar.Brand href="#home" className="brand">
          <Image src={logo} alt="logo" className="logo" />
          <Image src={flag} alt="flag" className="flag" />
        </Navbar.Brand>
        {isMobile && (
          <Button className="menu-toggle" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </Button>
        )}
        <Nav className={`ml-auto header-nav ${isMenuOpen ? 'show' : ''}`}>
          <NavDropdown title={<><FontAwesomeIcon icon={faUser} /> Log In</>} id="login-dropdown">
            <NavDropdown.Item href="https://abcswap.com/login"><FaUser /> Log In</NavDropdown.Item>
            <NavDropdown.Item href="https://abcswap.com/register"><FaRegUser /> Sign Up</NavDropdown.Item>
          </NavDropdown>
          <Button href="https://abcswap.com/posts/create" className="post-ad-btn">
            <SlNote /> Post Your Ad
          </Button>
          {/* <NavDropdown title={<FontAwesomeIcon icon={faGlobe} />} id="language-dropdown">
            <NavDropdown.Item href="#lang1">Language 1</NavDropdown.Item>
            <NavDropdown.Item href="#lang2">Language 2</NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
      </div>
    </Navbar>
  );
};

export default Header;